.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0D323B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  cursor: pointer;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  padding: 5px 0px;
  font-size: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 600px) {
  .App-header {
    font-size: 20px;
  }
}

@media(max-width: 400px) {
  .App-header {
    font-size: 15px;
  }
}






body {
  background-color: #d6e2ee;
}







.logo-head {
  height: 50vh;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 90px;
  margin-bottom: 50px;
}

.logo-head * {
  margin: 0 25px;
}

.seperator {
  display: flex;
  flex-direction: row;
  padding: 25px;
}

.feature-box {
  display: flex;
  flex-direction: row-reverse;
  height: fit-content;
}

.feature-box div {
  padding: 20px;
}

.feature-selector {
  display: flex;
  flex-direction: row;
  text-align: center;
  background-color: #0D323B;
  color: white;
  cursor: pointer;
  margin-top: 120px;
}

.feature-selector p {
  margin: 0;
  flex-grow: 1;
  border: 2px solid black;
  font-size: 18px;
  padding: 5px 0px;
}

.feature-section {
  width: 80%;
  margin: 100px auto;
}

.active-feature {
  background-color: #1c748a;
}

.logo-img {
  height: 70%;
  object-fit: contain;
}

.detections-gif {
  width: 100%;
  object-fit: contain;
}

.feature-image {
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
  border: 4px solid #0D323B;
}

.feature-sizing {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logo-text {
  font-size: 100px;
}

@media(max-width: 992px) {
  .seperator {
    flex-direction: column;
  }

  .logo-img {
    height: 40%;
  }

  .logo-text {
    font-size: 50px;
  }

  .feature-section {
    width: 90%;
  }

  .feature-selector {
    flex-wrap: wrap;
  }
}







.team-head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55vh;
  border-bottom: 4px solid #0D323B;
}

.team-head-bg {
  position: absolute;
  top: 0;
  left: 0;
  background: url("https://gymvisionstorage.blob.core.windows.net/images/team.jpg");
  background-position-y: 25%;
  opacity: .6;
  height: 55vh;
  width: 100%;
  z-index: -1;
  background-size: cover;
}

.team-text {
  font-size: 150px;
  color: white;
  -webkit-text-stroke: 5px #0D323B;
}

.team-members {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.team-members > div {
  width: 50%;
  padding: 50px;
  height: fit-content;
}

.team-row {
  margin: 50px 0px;
}

.flip-card-image {
  width: 100%;
  border-radius: 8px;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
  border: 4px solid #0D323B;
  border-radius: 15px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 8px;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  position: absolute;
  top: 0;
  background-color: #1c748a;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.flip-card-back p {
  font-size: 14px;
}

.flip-card-back h1 {
  font-size: 30px;
}

.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 25px;
  padding: 8px 0px;
}

@media(max-width: 1500px) {
  .team-members {
    flex-direction: column;
    align-items: center;
  }

  .team-members > div {
    width: 70%;
  }
}

@media(max-width: 780px) {
  .team-text {
    font-size: 100px;
    -webkit-text-stroke: 4px #0D323B;
  }

  .team-head-bg {
    margin-top: 40px;
    height: 35vh;
  }

  .team-members > div {
    width: 80%;
  }

  .team-head {
    margin-top: 40px;
    height: 35vh;
  }
}

@media(max-width: 650px) {
  .team-members > div {
    width: 90%;
  }

  .contact-info {
    font-size: 20px;
  }
}

@media(max-width: 550px) {
  .team-text {
    font-size: 60px;
    -webkit-text-stroke: 2px #0D323B;
  }

  .team-head-bg {
    margin-top: 30px;
    height: 25vh;
  }

  .team-head {
    margin-top: 30px;
    height: 25vh;
  }

  .team-members > div {
    width: 100%;
    padding: 10px;
  }
}

@media(max-width: 430px) {
  .flip-card-back p {
    font-size: 12px;
  }
}

@media(max-width: 380px) {
  .flip-card-back p {
    font-size: 10px;
  }
}







.tutorial {
  margin: 50px 0px 150px 0px;
}

.tutorial h1 {
  font-size: 75px;
}

hr:has(+h1) {
  margin-top: 100px;
  border: none;
  background-color: black;
  height: 3px;
  opacity: 1;
}

.tutorial-image {
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
  border: 2px solid #0D323B;
}

.image-col {
  margin: auto;
}

.setup-row {
  margin: 50px 0px;
}

@media(max-width: 992px) {
  .tutorial h1 {
    font-size: 60px;
  }
}

@media(max-width: 600px) {
  .tutorial h1 {
    font-size: 40px;
  }
}

@media(max-width: 400px) {
  .tutorial h1 {
    font-size: 30px;
  }
}